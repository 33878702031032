@import "variables";

@font-face {
  font-family: 'TransferWise-IconFont';
  src:  url('@{icomoon-font-path}/TransferWise-IconFont.eot?zg07su');
  src:  url('@{icomoon-font-path}/TransferWise-IconFont.eot?zg07su#iefix') format('embedded-opentype'),
    url('@{icomoon-font-path}/TransferWise-IconFont.ttf?zg07su') format('truetype'),
    url('@{icomoon-font-path}/TransferWise-IconFont.woff?zg07su') format('woff'),
    url('@{icomoon-font-path}/TransferWise-IconFont.svg?zg07su#TransferWise-IconFont') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'TransferWise-IconFont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ach {
  &:before {
    content: @icon-ach;
  }
}
.icon-add-profile {
  &:before {
    content: @icon-add-profile;
  }
}
.icon-add {
  &:before {
    content: @icon-add;
  }
}
.icon-alert-circle {
  &:before {
    content: @icon-alert-circle;
  }
}
.icon-alert {
  &:before {
    content: @icon-alert;
  }
}
.icon-bank {
  &:before {
    content: @icon-bank;
  }
}
.icon-bar-chart,
.icon-chart {
  &:before {
    content: @icon-chart;
  }
}
.icon-briefcase {
  &:before {
    content: @icon-briefcase;
  }
}
.icon-camera {
  &:before {
    content: @icon-camera;
  }
}
.icon-card {
  &:before {
    content: @icon-card;
  }
}
.icon-chat-pending {
  &:before {
    content: @icon-chat-pending;
  }
}
.icon-check-circle {
  &:before {
    content: @icon-check-circle;
  }
}
.icon-check {
  &:before {
    content: @icon-check;
  }
}
.icon-close-circle {
  &:before {
    content: @icon-close-circle;
  }
}
.icon-close {
  &:before {
    content: @icon-close;
  }
}
.icon-collapse {
  &:before {
    content: @icon-collapse;
  }
}
.icon-chat {
  &:before {
    content: @icon-chat;
  }
}
.icon-chats {
  &:before {
    content: @icon-chats;
  }
}
.icon-delete-profile {
  &:before {
    content: @icon-delete-profile;
  }
}
.icon-document {
  &:before {
    content: @icon-document;
  }
}
.icon-documents {
  &:before {
    content: @icon-documents;
  }
}
.icon-down-arrow {
  &:before {
    content: @icon-down-arrow;
  }
}
.icon-down {
  &:before {
    content: @icon-down;
  }
}
.icon-download {
  &:before {
    content: @icon-download;
  }
}
.icon-edit {
  &:before {
    content: @icon-edit;
  }
}
.icon-expand {
  &:before {
    content: @icon-expand;
  }
}
.icon-eye {
  &:before {
    content: @icon-eye;
  }
}
.icon-facebook-inverse {
  &:before {
    content: @icon-facebook-inverse;
  }
}
.icon-facebook {
  &:before {
    content: @icon-facebook;
  }
}
.icon-fast-flag {
  &:before {
    content: @icon-fast-flag;
  }
}
.icon-gift {
  &:before {
    content: @icon-gift;
  }
}
.icon-globe {
  &:before {
    content: @icon-globe;
  }
}
.icon-google {
  &:before {
    content: @icon-google;
  }
}
.icon-help-circle {
  &:before {
    content: @icon-help-circle;
  }
}
.icon-help {
  &:before {
    content: @icon-help;
  }
}
.icon-home {
  &:before {
    content: @icon-home;
  }
}
.icon-id {
  &:before {
    content: @icon-id;
  }
}
.icon-joint-account {
  &:before {
    content: @icon-joint-account;
  }
}
.icon-left-arrow {
  &:before {
    content: @icon-left-arrow;
  }
}
.icon-left {
  &:before {
    content: @icon-left;
  }
}
.icon-line-graph {
  &:before {
    content: @icon-line-graph;
  }
}
.icon-link {
  &:before {
    content: @icon-link;
  }
}
.icon-lock {
  &:before {
    content: @icon-lock;
  }
}
.icon-login {
  &:before {
    content: @icon-login;
  }
}
.icon-logout {
  &:before {
    content: @icon-logout;
  }
}
.icon-man {
  &:before {
    content: @icon-man;
  }
}
.icon-menu {
  &:before {
    content: @icon-menu;
  }
}
.icon-message {
  &:before {
    content: @icon-message;
  }
}
.icon-mobile {
  &:before {
    content: @icon-mobile;
  }
}
.icon-money {
  &:before {
    content: @icon-money;
  }
}
.icon-notification {
  &:before {
    content: @icon-notification;
  }
}
.icon-pdf {
  &:before {
    content: @icon-pdf;
  }
}
.icon-pending {
  &:before {
    content: @icon-pending;
  }
}
.icon-profile {
  &:before {
    content: @icon-profile;
  }
}
.icon-receipt {
  &:before {
    content: @icon-receipt;
  }
}
.icon-request {
  &:before {
    content: @icon-request;
  }
}
.icon-right-arrow {
  &:before {
    content: @icon-right-arrow;
  }
}
.icon-right {
  &:before {
    content: @icon-right;
  }
}
.icon-search {
  &:before {
    content: @icon-search;
  }
}
.icon-send {
  &:before {
    content: @icon-send;
  }
}
.icon-setting {
  &:before {
    content: @icon-setting;
  }
}
.icon-transfer {
  &:before {
    content: @icon-transfer;
  }
}
.icon-trash {
  &:before {
    content: @icon-trash;
  }
}
.icon-twitter {
  &:before {
    content: @icon-twitter;
  }
}
.icon-unlock {
  &:before {
    content: @icon-unlock;
  }
}
.icon-up-arrow {
  &:before {
    content: @icon-up-arrow;
  }
}
.icon-up {
  &:before {
    content: @icon-up;
  }
}
.icon-upload {
  &:before {
    content: @icon-upload;
  }
}
.icon-verification {
  &:before {
    content: @icon-verification;
  }
}
.icon-whatsapp {
  &:before {
    content: @icon-whatsapp;
  }
}
.icon-woman {
  &:before {
    content: @icon-woman;
  }
}
