.radio,
.checkbox {
  border-color: @input-border;
  > label {
    position: relative;
    padding-left: 48px;

    &:active:hover {
      border-color: @input-border-focus;

      input[type=radio],
      input[type=checkbox] {
        + .input-replacement {
          border-color: @input-border-focus;
        }
      }
    }

    input[type=radio],
    input[type=checkbox] {
      display: block;
      position: absolute;
      top: 13px;
      left: 40px;
    }
  }
}

.tw-checkbox-button {
  border: 1px solid @input-border;
  background: @input-bg;
  width: 22px;
  height: 22px;
  border-radius: @input-border-radius;
  display: inline-block;

  padding: 0;
  vertical-align: middle;
  text-align: center;

  transition: border 0.15s ease-in-out;

  .tw-checkbox-check {
    width: 20px;
    height: 20px;
    line-height: 18px;
    margin: 0;
    display: none;
    color: @brand-white;
  }

  .checkbox:hover &,
  &:hover {
    border-color: @input-border-hover;
  }

  &:focus,
  &.focus,
  &:focus:hover,
  &.focus:hover,
  &:active,
  &.active {
    border-color: @input-border-focus;
    outline: none;
  }

  &[checked],
  &.checked  {
    border-color: @input-border-focus !important;
    background-color: @input-border-focus;

    .tw-checkbox-check {
      display: inline-block;
    }
  }

  &[disabled] {
    background-color: @input-bg-disabled;
    border-color: @input-border-disabled !important;
    cursor: @cursor-disabled;

    .tw-checkbox-check {
      color: @input-border-disabled;
    }
  }

  .checkbox.has-error &,
  &.has-error {
    border-color: @brand-red !important;

    &[checked],
    &.checked  {
      background-color: @brand-red;
    }
    &[disabled] {
      background-color: @input-bg-disabled;
    }
  }
}

.tw-radio-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;

  border: 1px solid @input-border;
  background: @input-bg;
  display: inline-block;

  padding: 0;
  vertical-align: middle;

  transition: border 0.15s ease-in-out;

  .tw-radio-check {
    border-radius: 50%;
    text-align: center;
    width: 0px;
    height: 0px;
    margin: 0 0 0 11px;
    transition: all 0.15s ease-in-out;
  }

  .radio:hover &,
  &:hover {
    border-color: @input-border-hover;
  }

  &:focus,
  &.focus,
  &:active,
  &.active {
    border-color: @input-border-focus;
    outline: none;
  }

  &[checked],
  &.checked {
    border-color: @input-border-focus !important;

    .tw-radio-check {
      width: 12px;
      height: 12px;
      margin: 5px;
      display: block;
      background-color: @input-border-focus;
    }
  }
  &[disabled] {
    background-color: @input-bg-disabled;
    border-color: @input-border-disabled !important;
    cursor: @cursor-disabled;

    .tw-radio-check {
      background-color: @input-border-disabled;
    }
  }

  .radio.has-error &,
  &.has-error {
    border-color: @brand-red !important;

    &[checked] .tw-radio-check,
    &.checked .tw-radio-check {
      background-color: @brand-red;
    }
    &[disabled] .tw-radio-check {
      background-color: @input-border-disabled;
    }
  }
}

.checkbox {
  &.disabled label,
  &.disabled label:hover {
    border-color: @input-border-disabled;
    color: @input-color-disabled !important;
  }
}

.radio .tw-radio-button {
  position: absolute;
  left: 13px;
  top: 11px;
}
.checkbox .tw-checkbox-button {
  position: absolute;
  left: 14px;
  top: 12px;
}
.form-inline .tw-checkbox-button,
.form-inline .tw-radio-button {
  vertical-align: bottom;
}

.form-horizontal {
  .checkbox,
  .radio {
    padding-top: 0;

    > label {
        margin-bottom: 0;
    }
  }
}
