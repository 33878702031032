
.fade-in {
  animation: fade-in .3s 0s linear forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
