// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant(
    @color;
    @color-hover;
    @background;
    @background-hover;
    @background-active;
    @border;
    @border-hover;
    @border-active;
    @dropdown-background: @background) {

  color: @color;
  background-color: @background;
  border-color: @border;
  .transition(all 0.15s ease-in-out);

  &:focus,
  &.focus {
    color: @color-hover;
    background-color: @background-hover;
        border-color: @border-hover;
  }
  &:hover {
    color: @color-hover;
    background-color: @background-hover;
        border-color: @border-hover;
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @color-hover;
    background-color: @background-active;
        border-color: @border-active;

    &:hover,
    &:focus,
    &.focus {
      color: @color-hover;
      background-color: @background-active;
          border-color: @border-active;
    }
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: @btn-disabled-bg;
          border-color: @btn-disabled-bg;
                 color: @btn-disabled-color;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

// Button sizes
.button-size(@padding; @font-size; @line-height; @border-radius; @min-height) {
  padding: @padding;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;
  min-height: @min-height;

  .dropdown-menu {
      > li > a {
          font-size: @font-size;
          line-height: @line-height;
      }
  }
}


.button-outline(@color, @active, @inverse) {
    .button-variant(
        @color;
        @inverse;
        transparent;
        @color;
        @active;
        @color;
        @color;
        @active;
        #FFF
    );
}

.btn-sm {
  height: 32px;
}
