//
// Thumbnails
// --------------------------------------------------


// Mixin and adjust the regular image class
.thumbnail {
  display: block;
  padding: @thumbnail-padding;
  margin-bottom: @line-height-computed;
  line-height: @line-height-base;
  background-color: @thumbnail-bg;
  border: 1px solid @thumbnail-border;
  border-radius: @thumbnail-border-radius;
  position: relative;
  width: 100%;
  .transition(border .2s ease-in-out);

  > img,
  a > img {
    &:extend(.img-responsive);
    margin-left: auto;
    margin-right: auto;
    border-top-left-radius: @border-radius-base;
    border-top-right-radius: @border-radius-base;
  }

  a& {
    font-weight: 400;
    text-decoration: none;
  }

  // Add a hover state for linked versions only
  a&:hover,
  a&:focus,
  a&.active {
    border-color: @link-color;
  }

  // Image captions
  .caption {
    padding: @thumbnail-caption-padding;
    color: @thumbnail-caption-color;

    :last-child {
        margin-bottom: 0;
    }
  }
  .thumbnail-title {
    position: absolute;
    color: @brand-white;
    top: @thumbnail-caption-padding;
    margin-left: @thumbnail-caption-padding;
    margin-right: @thumbnail-caption-padding;
    z-index: 1;
  }
  .thumbnail-title + img {
    filter: brightness(50%);
  }
}
