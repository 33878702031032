.carousel-angled {
  .carousel-inner {
    @media (min-width: @screen-lg) {
      height: 76vh;
      min-height: 580px;
      max-height: 1096px;
    }

    .item {
      @media (min-width: @screen-lg) {
        height: 100%;

        &.active {
          .carousel-caption {
            opacity: 1;
            transition: 0.3s 0.1s;
          }
        }

        &.left,
        &.right {
          .carousel-caption {
            opacity: 0;
            transition: 0.1s;
          }
        }
      }

      > img {
        width: 100%;
        height: 36vh;
        object-fit: cover;
        @media (min-width: @screen-lg) {
          position: absolute;
          height: inherit;
          margin-top: (@spacer * 12);
        }
      }
    }
  }

  .carousel-caption {
    display: flex;
    z-index: 0;
    position: relative;
    bottom: inherit;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: (@spacer * 25);
    padding: (@spacer * 2);
    background-color: @brand-white;
    color: @text-color;
    text-align: center;
    text-shadow: none;
    @media (min-width: @screen-lg) {
      top: (@spacer * 12);
      align-items: flex-start;
      width: 40%;
      height: 85%;
      padding: (@spacer * 4) 6% (@spacer * 4) 10%;
      transform: translateY((-@spacer * 8));
      background: none;
      text-align: left;
      opacity: 0;
    }

    &:before {
      @media (min-width: @screen-lg) {
        z-index: -1;
        position: absolute;
        top: 0;
        left: -50%;
        width: 170%;
        height: 100%;
        transform: skew(-23deg);
        background-color: @brand-white;
        box-shadow: 0 20px 66px 0 rgba(34,48,73,.2);
        content: "";
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: @brand-blue-plus-10;
    }
  }

  .carousel-caption-item {
    width: 100%;
    padding: 0 (@spacer * 2);
    @media (min-width: @screen-lg) {
      width: 33.33333333%;
      padding: 0 (@spacer * 4);
      transform: translateY((-@spacer * 8));
    }
  }

  .carousel-control-container {
    display: none;
    @media (min-width: @screen-lg) {
      display: block;
      position: relative;
      transform: translateX((-@spacer * 4));
    }
  }

  .carousel-control {
    top: inherit;
    right: (@spacer * 10);
    left: inherit;
    width: (@spacer * 10);
    height: (@spacer * 10);
    background: @brand-white;

    + .carousel-control {
      right: 0;
    }
  }

  .carousel-indicators {
    position: relative;
    bottom: inherit;
    left: inherit;
    width: 100%;
    margin: 0;
    padding: (@spacer * 2) 0 (@spacer * 2);
    background: @brand-white;
    @media (min-width: @screen-lg) {
      display: none;
    }

    li {
      width: @spacer;
      height: @spacer;
      border: 0;
      background-color: @brand-smoke;

      &.active {
        background-color: @brand-light-blue;
      }
    }
  }
}
