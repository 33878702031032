//
// Component animations
// --------------------------------------------------

// Heads up!
//
// We don't use the `.opacity()` mixin here since it causes a bug with text
// fields in IE7-8. Source: https://github.com/twbs/bootstrap/pull/3552.

.fade {
  opacity: 0;
  .transition(opacity .15s linear);
  &.in {
    opacity: 1;
  }
}

.collapse {
  display: none;

  &.in      { display: block; }
  tr&.in    { display: table-row; }
  tbody&.in { display: table-row-group; }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  .transition-property(~"height, visibility");
  .transition-duration(.35s);
  .transition-timing-function(ease);
}

[data-toggle=collapse] {
    text-decoration: none;
    
    &:hover,
    &:focus  {
        text-decoration: none;
    }
    .caret {
        transition: transform .15s linear;
        margin-top: -3px;
    }
    &[aria-expanded=false] .caret,
    &.collapsed .caret {
        transform: rotate(-90deg);
    }
}
