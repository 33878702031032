//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
  display: inline-block;
  padding-left: 0;
  margin-bottom: @line-height-computed;

  > li {
    display: inline; // Remove list-style and block-level defaults
    > a,
    > span {
      position: relative;
      float: left; // Collapse white-space
      line-height: (@line-height-computed * 2);
      text-decoration: none;
      color: @pagination-color;
      margin: 0 4px;
      width: (@line-height-computed * 2);
      text-align: center;
      border-radius: 50%;
      font-weight: @pagination-font-weight;
    }

    &:first-child > a,
    &:first-child > span {
      margin-left: 0;
    }
    &:last-child > a,
    &:last-child > span {
      margin-right: 0;
    }
  }

  > li > a {
    &:hover,
    &:focus {
      z-index: 3;
      color: @pagination-hover-color;
      background-color: @pagination-hover-bg;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      z-index: 2;
      color: @pagination-active-color;
      background-color: @pagination-active-bg;
      cursor: default;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: @pagination-disabled-color;
      background-color: @pagination-disabled-bg;
      cursor: @cursor-disabled;
    }
  }
}

.pagination-text {
  color: @headings-color;
  line-height: (@line-height-computed * 2);
  margin-bottom: 0;

  .btn-group {
    vertical-align: top;
    margin-top: -1px;
  }
}

.pagination-inverse {
  > li > a:hover,
  > li > a:focus,
  > li.active > a,
  > li.active > span,
  > li.active > a:hover,
  > li.active > a:focus {
    background-color: @pagination-inverse-hover-bg;
  }
}
