//
// Labels
// --------------------------------------------------

.label {
  display: inline;
  padding: .3em .6em .2em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: @label-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;

  // Add hover effects, but only for links
  & {
    &:hover,
    &:focus {
      color: @label-link-hover-color;
      text-decoration: none;
      cursor: pointer;
    }
  }

  // Empty labels collapse automatically (not available in IE8)
  &:empty {
    display: none;
  }

  // Quick fix for labels in buttons
  .btn & {
    position: relative;
    top: -1px;
  }
}

// Colors
// Contextual variations (linked labels get darker on :hover)

.label-default {
  .label-variant(@label-default-bg, @label-default-bg-hover);
  color: @gray;
}

.label-primary {
  .label-variant(@label-primary-bg, @label-primary-bg-hover);
}

.label-success {
  .label-variant(@label-success-bg, @label-success-bg-hover);
}

.label-info {
  .label-variant(@label-info-bg, @label-info-bg-hover);
}

.label-warning {
  .label-variant(@label-warning-bg, @label-warning-bg-hover);
}

.label-danger {
  .label-variant(@label-danger-bg, @label-danger-bg-hover);
}
