@font-face {
    font-family: 'Averta';
    src: url('../fonts/TW-Averta-Regular.eot');
    src: url('../fonts/TW-Averta-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TW-Averta-Regular.woff2') format('woff2'),
         url('../fonts/TW-Averta-Regular.woff') format('woff'),
         url('../fonts/TW-Averta-Regular.ttf') format('truetype');
    font-weight: 500;
}
@font-face {
    font-family: 'Averta';
    src: url('../fonts/TW-Averta-Semibold.eot');
    src: url('../fonts/TW-Averta-Semibold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TW-Averta-Semibold.woff2') format('woff2'),
         url('../fonts/TW-Averta-Semibold.woff') format('woff'),
         url('../fonts/TW-Averta-Semibold.ttf') format('truetype');
    font-weight: 600;
}
@font-face {
    font-family: 'Averta';
    src: url('../fonts/TW-Averta-Bold.eot');
    src: url('../fonts/TW-Averta-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TW-Averta-Bold.woff2') format('woff2'),
         url('../fonts/TW-Averta-Bold.woff') format('woff'),
         url('../fonts/TW-Averta-Bold.ttf') format('truetype');
    font-weight: 800;
}
