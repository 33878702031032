.media {
  display: flex;
}
.media + .media {
    margin-top: (@spacer * 3);
}
.media-body {
  flex: 1;

  > *:last-child {
      margin-bottom: 0;
  }
}
.media-middle {
  align-self: center;
}
.media-bottom {
  align-self: flex-end;
}


//
// Images/elements as the media anchor
//

.media-object {
  display: block;
}


//
// Alignment
//

.media-right {
  padding-left: @spacing-1-mobile;
}

.media-left {
  padding-right: @spacing-1-mobile;
}


@media (min-width: @screen-sm-min) {
  .media-right {
    padding-left: @spacing-1-tablet;
  }

  .media-left {
    padding-right: @spacing-1-tablet;
  }
}



//
// Headings
//

.media-heading {
  margin-top: 0;
  margin-bottom: 0;
}


//
// Media list variation
//

.media-list {
  padding-left: 0;
  list-style: none;
}

//
// Fix text-ellipsis issue with text in dropdown
//

.dropdown-menu {
  .media-body {
    overflow: hidden
  }
}
