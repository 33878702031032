//
// Close icons
// --------------------------------------------------


.close {
  float: right;
  font-size: (@font-size-base * 2);
  font-weight: @close-font-weight;
  line-height: @line-height-computed;
  color: @close-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: @close-color-hover;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    .tab-focus();
  }

  &:active {
    color: @close-color-active;
  }

  // Additional properties for button version
  // iOS requires the button element instead of an anchor tag.
  // If you want the anchor version, it requires `href="#"`.
  // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
  button& {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
}
