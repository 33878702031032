
.sequence-vertical-spacing(
  @sequence-margin,
  @sequence-item-spacing-mobile,
  @sequence-item-spacing-desktop,
  @pip-offset-mobile,
  @pip-offset-desktop,
  @sequence-step-height,
  @sequence-icon-size
) {
  @sequence-outside-spacing: (@sequence-margin + @sequence-item-spacing-desktop);

  > li {
    padding-top: @sequence-item-spacing-mobile;
    padding-bottom: @sequence-item-spacing-mobile;;

    &:first-child {
      margin-top: @sequence-margin;
    }
    &:last-child {
      margin-bottom: @sequence-margin;
    }
    @media (min-width: @screen-md-min) {
      padding-top: @sequence-item-spacing-desktop;
      padding-bottom: @sequence-item-spacing-desktop;
    }
    &:before {
      margin-top: ((@sequence-step-height / 2) - (@sequence-pip-size / 2));
    }
    &:after {
      top: @pip-offset-desktop;
    }
    &:first-child:after {
      height: 100%;
    }
    &:last-child:after {
      height: 0;
    }
  }

  &.sequence-top {
    > li:first-child {
      padding-top: @sequence-outside-spacing;
      margin-top: 0;

      &:after {
        height: ~"calc(100% + @{pip-offset-desktop})";
      }
    }
  }
  &.sequence-bottom {
    > li:last-child {
      padding-bottom: @sequence-outside-spacing;
      margin-bottom: 0;

      &:after {
        height: ~"calc(100% - @{pip-offset-desktop})";
      }
    }
  }
  &.sequence-top.sequence-bottom {
    > li:first-child:last-child:after {
      height: 100%;
    }
  }

  .sequence-icon {
    top: ((@sequence-step-height / 2) - (@sequence-icon-size / 2) + @sequence-item-spacing-mobile);

    @media (min-width: @screen-md-min) {
      top: ((@sequence-step-height / 2) - (@sequence-icon-size / 2) + @sequence-item-spacing-desktop);
    }
  }
  &.sequence-top > li:first-child .sequence-icon {
    top: (((@sequence-step-height / 2) - (@sequence-icon-size / 2)) + @sequence-outside-spacing);
  }
}

.sequence-horizontal-spacing(
  @sequence-item-padding,
  @sequence-pip-size,
  @sequence-icon-sm,
  @sequence-icon-md,
  @sequence-icon-lg
) {
  padding-left: @sequence-item-padding;
  > li {
    &:before {
      margin-left: (-@sequence-item-padding - (@sequence-pip-size / 2) + 1px);
    }
    &:after {
      left: -@sequence-item-padding;
    }
  }
  .sequence-icon {
    margin-left: (-@sequence-item-padding - (@sequence-icon-sm / 2) + 1px);
  }
  .sequence-icon-md {
    margin-left: (-@sequence-item-padding - (@sequence-icon-md / 2) + 1px);
  }
  .sequence-icon-lg {
    margin-left: (-@sequence-item-padding - (@sequence-icon-lg / 2) + 1px);
  }
}

.sequence-variant(@type, @color) {
  @variant: ~'.sequence-@{type}';

  @{variant} {
    .sequence-variant-detail(
      @color,
      @pip-offset-sm, @pip-offset-md, @pip-offset-lg,
      @sequence-border, @sequence-inverse-border, @sequence-margin-lg
    );
  }

  @keyframes ~'sequence-pip-@{type}' {
    from { border-color: @sequence-border; }
    to { border-color: @color; }
  }
}

// TODO update description
// The line for the active item needs to be the active colour before the 'pip' and
// the inactive colour afterwards.  This is tricky because we don't know the height.
// However, we can achieve this with one element by using a gradient with a hard
// transition between colours at the right point.  We need several variants to cover
// our large and inverse options.
.sequence-variant-detail(
  @color,
  @pip-offset-sm,
  @pip-offset-md,
  @pip-offset-lg,
  @sequence-border,
  @sequence-inverse-border,
  @sequence-margin
) {
  > li {
    border-color: @color;
    &:after {
      background-image: linear-gradient(@color 0, @color 50%, @sequence-border 50%, @sequence-border 100%);
    }
  }
  &.sequence-inverse > li {
    &:after {
      background-image: linear-gradient(@color 0, @color 50%, @sequence-inverse-border 50%, @sequence-inverse-border 100%);
    }
  }

  > .active {
    &:before {
      border-color: @color;
      background-color: @color;
    }
  }
}

.sequence-animation-variant(@type) {
  > li:before {
    animation:
      @speed-per-item linear 0.3s both ~"sequence-pip-@{type}",
      @speed-per-pulse linear 0.3s forwards ~"sequence-pulse-@{type}";
  }
  > .active:before {
    animation:
      @speed-per-item linear 0.3s both ~"sequence-pip-@{type}",
      @speed-per-pulse linear 0.3s infinite ~"sequence-pulse-@{type}";
  }
}

.sequence-animation-delays(@counter) when (@counter > 0) {
  .sequence-animation-delays((@counter - 1));

  > li:nth-child(@{counter}):before,
  > li:nth-child(@{counter}):after {
    animation-delay: (@counter * @speed-per-item) !important;
  }
}
