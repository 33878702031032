// Labels

.label-variant(@color, @hover) {
  background-color: @color;

  &[href] {
    &:hover,
    &:focus {
      background-color: @hover;
    }
  }
}
