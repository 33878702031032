.responsive-spacing(panel, @spacing-1-mobile, @spacing-1-tablet, @spacing-1-desktop);
.responsive-spacing-vertical(section-1, @spacing-1-mobile, @spacing-1-tablet, @spacing-1-desktop);
.responsive-spacing-vertical(section-2, @spacing-2-mobile, @spacing-2-tablet, @spacing-2-desktop);
.responsive-spacing-vertical(section-3, @spacing-3-mobile, @spacing-3-tablet, @spacing-3-desktop);
.responsive-spacing-vertical(section-5, @spacing-5-mobile, @spacing-5-tablet, @spacing-5-desktop);
.responsive-spacing-vertical(section-7, @spacing-7-mobile, @spacing-7-tablet, @spacing-7-desktop);

.spacing(0, 0);
.spacing(1, @spacer);
.spacing(2, (2 * @spacer));
.spacing(3, (3 * @spacer));

.m-x-auto { margin-right: auto !important; margin-left: auto !important; }

.m-t-4 { margin-top: (@spacer-y * 4) !important; }
.m-b-4 { margin-bottom: (@spacer-y * 4) !important; }

.m-t-5 { margin-top: (@spacer-y * 5) !important; }
.m-b-5 { margin-bottom: (@spacer-y * 5) !important; }

.p-t-4 { padding-top: (@spacer-y * 4) !important; }
.p-b-4 { padding-bottom: (@spacer-y * 4) !important; }

.p-t-5 { padding-top: (@spacer-y * 5) !important; }
.p-b-5 { padding-bottom: (@spacer-y * 5) !important; }

.section {
  padding-top: @spacing-3-mobile;
  padding-bottom: @spacing-3-mobile;

  @media (min-width: @screen-sm-min) {
    padding-top: @spacing-3-tablet;
    padding-bottom: @spacing-3-tablet;
  }
  @media (min-width: @screen-lg-min) {
    padding-top: @spacing-3-desktop;
    padding-bottom: @spacing-3-desktop;
  }
}

.footer {
  padding-top: @spacing-1-mobile;

  @media (min-width: @screen-sm-min) {
    padding-top: @spacing-1-tablet;
  }
  @media (min-width: @screen-lg-min) {
    padding-top: @spacing-1-desktop;
  }
}
