.circle-variant(@diameter; @font-size; @icon-size) {
  font-size: @font-size;
  width: @diameter;
  height: @diameter;
  line-height: @diameter;

  .glyphicon,
  .icon {
    font-size: @icon-size;
    line-height: (@diameter - 2px);
  }
}
