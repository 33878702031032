//
// Grid system
// --------------------------------------------------


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  .container-fixed();

  @media (min-width: @screen-xl-min) {
    max-width: @container-xl;
  }
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
  .container-fixed();
}

.container {
  padding-right: @spacing-1-mobile;
  padding-left: @spacing-1-mobile;

  @media (min-width: @screen-sm-min) {
    padding-right: @spacing-1-tablet;
    padding-left: @spacing-1-tablet;
  }
  @media (min-width: @screen-lg-min) {
    padding-right: @spacing-1-desktop;
    padding-left: @spacing-1-desktop;
  }
}

// Row
//
// Rows contain and clear the floats of your columns.

.row {
  .make-row();
}


// Columns
//
// Common styles for small and large grid columns

.make-grid-columns();


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

.make-grid(xs);


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: @screen-sm-min) {
  .make-grid(sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: @screen-md-min) {
  .make-grid(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: @screen-lg-min) {
  .make-grid(lg);
}

// Extra Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: @screen-xl-min) {
  .make-grid(xl);
}

@media (max-width: @screen-xs-max) {
  .container {
    padding-right: @spacing-1-mobile;
    padding-left: @spacing-1-mobile;
  }
}
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .container {
    padding-right: @spacing-1-tablet;
    padding-left: @spacing-1-tablet;
  }
}

[class*="col-sm"],
[class*="col-md"],
[class*="col-lg"],
[class*="col-xl"] {
  margin-bottom: (@spacer-y * 2);
}

[class*="col-xs"] {
  margin-bottom: 0;
}

@media (min-width: @screen-sm-min) {
  [class*="col-sm"] {
    margin-bottom: 0;
  }
}

@media (min-width: @screen-md-min) {
  [class*="col-md"] {
    margin-bottom: 0;
  }
}

@media (min-width: @screen-lg-min) {
  [class*="col-lg"] {
    margin-bottom: 0;
  }
}

@media (min-width: @screen-xl-min) {
  [class*="col-xl"] {
    margin-bottom: 0;
  }
}

.row-equal-height {
  display: flex;
  flex-wrap: wrap;

  > [class*="col-"] {
    display: flex;
  }

  @media (max-width: @screen-xs-max) {
    .col-xs-12 {
      margin-bottom: 12px;
    }
  }
}

// Make grid gutters smaller on mobile
@media (max-width: @screen-xs-max) {
  .row {
    margin-right: -8px;
    margin-left: -8px;
  }
  [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px;
  }
}
