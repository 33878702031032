//
// Utility classes
// --------------------------------------------------


// Floats
// -------------------------

.clearfix {
  .clearfix();
}
.center-block {
  .center-block();
}

.pull-right,
.pull-xs-right {
  float: right !important;
}
.pull-left,
.pull-xs-left  {
  float: left !important;
}
.pull-xs-none  {
  float: none !important;
}

@media (min-width: @screen-sm-min) {
  .pull-sm-left {
    float: left !important;
  }
  .pull-sm-right {
    float: right !important;
  }
  .pull-sm-none {
    float: none !important;
  }
}
@media (min-width: @screen-md-min) {
  .pull-md-left {
    float: left !important;
  }
  .pull-md-right {
    float: right !important;
  }
  .pull-md-none {
    float: none !important;
  }
}
@media (min-width: @screen-lg-min) {
  .pull-lg-left {
    float: left !important;
  }
  .pull-lg-right {
    float: right !important;
  }
  .pull-lg-none {
    float: none !important;
  }
}
@media (min-width: @screen-xl-min) {
  .pull-xl-left {
    float: left !important;
  }
  .pull-xl-right {
    float: right !important;
  }
  .pull-xl-none {
    float: none !important;
  }
}

// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  .text-hide();
}


// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
  display: none !important;
}


// For Affix plugin
// -------------------------

.affix {
  position: fixed;
}


.rotate90 {
  transform: rotate(90deg);
}
.rotate180 {
  transform: rotate(180deg);
}
.rotate270 {
  transform: rotate(270deg);
}
.rotate-90 {
  transform: rotate(-90deg);
}
