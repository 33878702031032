// Form validation states
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.

.form-control-validation(@text-color: #555; @border-color: #ccc; @border-hover: #aaa; @background-color: #f5f5f5) {
  border-color: @border-color !important;

  // Color the label and help text
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label,
  .radio label,
  .checkbox label,
  .radio-inline label,
  .checkbox-inline label,
  .input-group-addon {
    color: @text-color !important;
    border-color: @border-color !important;
  }

  // Set the border and box shadow on specific inputs to match
  .form-control,
  .btn-input,
  .btn-input:active,
  .btn-input:focus,
  .btn-input:hover,
  .input-group-addon,
  .input-group .form-control:focus,
  .alert {
    border-color: @border-color !important;
  }

  &:hover,
  &:focus-within {
    .form-control,
    &.radio label,
    &.checkbox label,
    .btn-input,
    .input-group-addon,
    .input-group .form-control:focus,
    .alert {
      border-color: @border-hover !important;
    }
  }

  // Optional feedback icon
  .form-control-feedback {
    color: @text-color;
  }

  .radio,
  &.checkbox {
    > label {
      &:not(.disabled) {
        border-color: @border-color;
        color: @input-color;
      }
      &:hover,
      &:focus-within {
        border-color: @border-hover !important;

        .tw-checkbox-button,
        .tw-radio-button {
          border-color: @border-hover !important;
        }
      }
    }

    .tw-checkbox-button,
    .tw-radio-button {
      border-color: @border-color;
    }
  }
}



// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `@input-border-focus` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
.form-control-focus(@color: @input-border-focus) {
  @color-rgba: rgba(red(@color), green(@color), blue(@color), .6);
  &:focus {
    border-color: @color;
    outline: 0;
  }
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!
.input-size(@input-height; @input-padding; @font-size; @line-height; @border-radius) {
  height: @input-height;
  padding: @input-padding;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;

  select& {
    height: @input-height;
    line-height: @input-height;
  }

  textarea&,
  select[multiple]& {
    height: auto;
  }
}
