
.navbar {
  .glyphicon,
  .icon {
    font-size: 22px;
    line-height: 22px;

    + .badge {
      margin-top: -25px;
      margin-left: -12px;
      min-width: 18px;
      padding: 2px 3px;
      line-height: 14px;
    }
  }
}

.dropdown-menu,
.nav,
.btn {
  .icon {
    font-size: 24px;
    vertical-align: middle;
  }
}

.icon {
  .btn & {
    line-height: 23px;
  }
  .circle & {
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
}

a.icon {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.icon-sm {
  font-size: 16px;
}
.icon-md {
  font-size: 24px;
}
.icon-lg {
  font-size: 32px;
}
.icon-xl {
  font-size: 40px;
}
.icon-xxl {
  font-size: 48px;
}
